.ccm-root {
    .ccm-modal {
        &.ccm-widget {
            .ccm-widget {
                &--buttons {
                    .button,
                    button {
                        @include hover-focus-visible {
                            color: var(--color-primary, $color__primary);
                        }
                    }
                }
            }
        }

        &.ccm-control-panel {
            .ccm-modal {
                &--footer {
                    > .button,
                    > button {
                        @include hover-focus-visible {
                            color: var(--color-primary, $color__primary);
                        }
                    }
                }
            }
        }

        &.ccm-details {
            .ccm-modal {
                &--footer {
                    > .button,
                    > button {
                        @include hover-focus-visible {
                            color: var(--color-primary, $color__primary);
                        }
                    }
                }
            }
        }
    }
}
