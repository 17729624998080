.meta-navigation {
    &--accessibility {
        .meta-navigation {
            &__link {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                padding-top: 4px;
                padding-bottom: 4px;
                @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 15px, 17px);

                span {
                    &.icon {
                        margin-right: 20px;

                        img {
                            filter: $filter__white;
                        }
                    }
                }
            }
        }
    }

    &--crumb {
        .section--crumb.section--bg-dark &{

            .meta-navigation__item:after,
            .meta-navigation__nav > ol > li > a::before{
                filter: $filter__white;
            }
        }
    }

    &--legal {
        .meta-navigation {

            &__list{
                line-height: calc($font__line-height * 1.1);
            }

            &__link {
                padding-top: 4px;
                padding-bottom: 4px;

                @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 15px, 17px);
            }

            &__button {
                appearance: none;
                padding: 4px 0;
                @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 15px, 17px);

                font-weight: $font__weight--bold;
                text-decoration: underline;
                text-decoration-thickness: .08em;

                color: $color__white;
                background-color: transparent;
                border: 0;
                transition: color .25s linear;

                &:hover,
                &:focus {
                    text-decoration: none;
                    //color: $color__link--hover;
                }
            }
        }
    }

    &--social {
        .meta-navigation {
            &__list {
                display: flex;
                flex-flow: row wrap;
                gap: 8px;
            }

            // &__item {
            //     &:not(:last-child) {
            //         margin-right: 8px;
            //     }
            // }
        }
    }

    &--staticlinks {
        &.meta-navigation--sticky {
            @if $main-navigation__mode == menu {
                @media (min-width: #{$off-canvas__breakpoint}) {
                    display: none !important;
                }
            }

            position: fixed;
            z-index: $z-index__header - 2;
            top: calc(#{$header__height--mobile} + (100vw / 4 * 3 / 2));
            top: calc(#{$header__height--mobile} + ((var(--vw, 1vw) * 100) / 4 * 3 / 2));
            right: 24px;
            transform: translateY(-50%);

            @include media-breakpoint-up(md) {
                top: calc(#{$header__height--mobile} + (100vw / 21 * 9 / 2));
                top: calc(#{$header__height--mobile} + ((var(--vw, 1vw) * 100) / 21 * 9 / 2));
            }

            @include media-breakpoint-between(lg, xxl) {
                top: calc(#{$header__height--unpinned} + ((525 / 1920 * #{map-get($grid-breakpoints, xxl)}) / 2));
            }

            @include media-breakpoint-up(xxl) {
                top: calc(#{$header__height} + (100vw / 1920 * 525 / 2));
                top: calc(#{$header__height} + ((var(--vw, 1vw) * 100) / 1920 * 525 / 2));
            }

            @media (min-width: 1920px) {
                top: calc(#{$header__height} + (525px / 2));
            }

            body:not([data-template='home']):not(.has-banner-image) &,
            body[data-template='home']:not(.has-banner-slider) & {
                display: none;
            }

            body:not([data-template='home']).has-banner-image &,
            body[data-template='home'].has-banner-slider & {
                @include media-breakpoint-down(xxl) {
                    position: absolute;
                }
            }

            body[data-template='home'].has-banner-slider.banner-fullscreen & {
                top: 50vh;
                top: calc(var(--vh, 1vh) * 50);
            }
        }

        &:not(.meta-navigation--sticky) {
            @if $main-navigation__mode == menu {
                display: none;

                @media (min-width: #{$off-canvas__breakpoint}) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
            }

            .meta-navigation {
                &__link {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 48px;
                    height: 48px;
                    transition: background-color .25s linear;

                    @include hover-focus-visible {
                        background-color: $color__primary--hover;

                        span {
                            &.icon {
                                filter: $filter__white;
                            }
                        }
                    }

                    span {
                        &.icon {
                            filter: $filter__primary;
                            transition: filter .25s linear;
                        }
                    }
                }
            }
        }

        .meta-navigation {
            &__list {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 8px;

                .container-xxl & {
                    flex-direction: row;
                    align-items: center;
                    gap: 0;
                }
            }

            &__link {
                display: inline-flex;
                flex-direction: row;
                align-items: center;

                &.button {
                    gap: 0;

                    @include hover-focus-visible {
                        span {
                            &:not(.icon) {
                                // padding-right: 8px;
                                padding-left: 8px;
                                max-width: 200px;
                                transition: padding-left .25s linear, max-width .25s linear .1s;
                            }
                        }
                    }

                    span {
                        // &.icon {
                        //     margin-right: 8px;
                        // }

                        &:not(.icon) {
                            max-width: 0;
                            overflow: hidden;
                            transition: padding-left .25s linear .2s, max-width .25s linear;
                        }
                    }
                }
            }
        }
    }

    &--quicklinks {
        .meta-navigation {
            &__item {
                display: flex;
                padding-left: 0;

                &:not(:first-child) {
                    margin-top: 5px;
                }
            }

            &__link {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                min-width: 200px;

                span {
                    &.icon {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}
