.splide {
    &__track {

    }

    &__list {

    }

    &__slide {
        background-color: $color__secondary;

        &.is-current{
            background-color: transparent;
        }
    }

    &__footer {

        header.page__header .banner &{

            .splide{

                &__navigation{

                }

                &__pagination{
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0%);

                    @include media-breakpoint-up(md) {
                        top: calc(50% - 40px);
                        transform: translate(-50%, -50%);
                    }

                    li {

                        button {
                            color: $color__white;

                            &::before {
                                background-color: $color__white!important;
                            }

                            @include hover-focus-visible {
                                border-color: transparentize($color: $color__white, $amount: 0.5);
                            }

                            &.is-active {
                                border-color: $color__white;

                                @include hover-focus-visible {
                                    border-color: transparentize($color: $color__white, $amount: 0.5);
                                }
                            }
                        }
                    }
                }
            }
        }

        .page__main div.image-slider > &{
            margin: 20px 0 40px 0;

            @include media-breakpoint-up(md) {
                margin: 55px 0 0 0;
            }

            .splide__navigation{

                @include media-breakpoint-up(xl) {
                    width: 75%;
                }
            }

            .splide__arrows{
                position: relative;
                top: 23px;

                @include media-breakpoint-up(lg) {
                    top: 25px;
                }
            }
        }

        .section--bg-dark .image-slider &{

            .splide__pagination{

                li button{

                    &:before{
                        background-color: $color__white!important;
                    }

                    &.is-active{
                        border-color: $color__white;

                        @include hover-focus-visible{
                            border-color: transparentize($color: $color__white, $amount: 0.5);
                        }
                    }

                    @include hover-focus-visible{
                        border-color: transparentize($color: $color__white, $amount: 0.5);
                    }
                }
            }
        }

        .splide__navigation{
            z-index: 1;
            justify-content: center;

            @include media-breakpoint-up(md) {
                justify-content: flex-end;
            }

            .splide__pagination{

                @include media-breakpoint-down(md) {
                    gap: 0 2px!important;
                }

                @include media-breakpoint-up(md) {
                    position: absolute;
                    top: calc(50% - 40px);
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                li {
                    display: flex;

                    button {
                        width: 22px;
                        height: 22px;
                        color: $color__primary;

                        background-image: none;
                        background-color: transparent;

                        border: 2px solid transparent;
                        border-radius: 0;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 50%;

                            height: 12px;
                            width: 12px;
                            border: none;
                            border-radius: 0;

                            background-color: $color__primary!important;
                            transform: translate(-50%, -50%);
                        }

                        @include hover-focus-visible {
                            border-color: $color__font;
                        }

                        &.is-active {
                            width: 22px;
                            border-color: $color__primary;

                            @include hover-focus-visible {
                                border-color: $color__font;
                            }
                        }
                    }
                }
            }

            div.splide__arrows{

            }
        }
    }

    &__navigation {
        justify-content: flex-end;
        width: 100%;
        margin: 0 auto;

        &:after{
            display: none;
        }
    }

    &__arrows {

    }

    &__arrow {
        color: $color__white;
        background-color: $color__primary;

        box-shadow: 0 2px 5px rgba(0,0,0,.5);
        border-radius: 0;

        img{
            filter: invert(1);
        }

        @include hover-focus-within{
            color: $color__primary;
            background-color: $color__white;

            img{
                filter: $filter__primary;
            }
        }

        &--prev{
            transform: none;
        }
    }

    &__toggle {
        color: $color__white;
        background-color: $color__primary;

        box-shadow: 0 2px 5px rgba(0,0,0,.5);
        border-radius: 0;
        margin-right: 4px!important;

        img{
            filter: invert(1);
        }

        @include hover-focus-within{
            color: $color__primary;
            background-color: $color__white;

            img{
                filter: $filter__primary;
            }
        }
    }

    &__pagination {

    }
}
