@mixin h1 {
    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 28px, 46px);
    font-weight: $font__weight--light;
    line-height: 1.3392857143;
}

@mixin h2 {
    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 24px, 38px);
    font-weight: $font__weight--light;
    line-height: 1.4;
}

@mixin h3 {
    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 20px, 26px);
    font-weight: $font__weight--normal;
    line-height: 1.4;
}

@mixin h4 {
    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 18px, 23px);
    font-weight: $font__weight--semibold;
    line-height: 1.6315789474;
}

@mixin h5 {
    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 18px, 20px);
    font-weight: $font__weight--semibold;
    line-height: 1.6315789474;
}

@mixin margins-headings {
    h1,
    .h1 {
        margin-bottom: $margin__headings--h1;
    }

    h2,
    .h2 {
        margin-bottom: $margin__headings--h2;
    }

    h3, h4, h5, h6,
    .h3, .h4, .h5, .h6 {
        margin-bottom: $margin__headings--h3;
    }
}

h1, h2, h3, h4, h5, h6 {
    //font-family: inherit;
    //font-family: $font__family--heading;
    font-size: inherit;
    font-weight: normal;
    line-height: inherit;
    color: $color__secondary;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
    display: block;
    font-family: $headings-font-family;
}

// body[class*='cke_'],
// .page__main,
// .page__footer {
    h1 {
        @include h1;
        // color: $color__primary;
    }

    h2 {
        @include h2;
        // color: $color__primary;
    }

    h3 {
        @include h3;
        // color: $color__primary;
    }

    h4 {
        @include h4;
    }

    h5, h6 {
        @include h5;
    }

    .h1 {
        @include h1;
        // color: $color__primary;
    }

    .h2 {
        @include h2;
        // color: $color__primary;
    }

    .h3 {
        @include h3;
        // color: $color__primary;
    }

    .h4 {
        @include h4;
    }

    .h5, .h6 {
        @include h5;
    }
// }
