.content {
    &.content--margin-top {
        margin-top: 55px;
    }

    &.content--margin-bottom {
        margin-bottom: 55px;
    }

    @include media-breakpoint-down(xl) {

        &.content--margin-top {
            margin-top: 48px;
        }

        &.content--margin-bottom {
            margin-bottom: 48px;
        }
    }

    &.content--heading,
    &.content--headline{

        &.content--margin-top {
            margin-top: 16px;
        }

        &.content--margin-bottom {
            margin-bottom: 16px;
        }
    }
}
