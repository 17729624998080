.page {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;

    &__wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
    }

    &__header {
        position: relative;

        &::before {
            content: "";
            position: fixed;
            z-index: $z-index__header;
            top: 0; right: -32px; left: -32px;
            height: $logo__height--mobile;
            background-color: $header__background-color;
            box-shadow: $header__box-shadow;
            pointer-events: none;

            @include media-breakpoint-up(lg) {
                height: $header__height--unpinned;
            }
        }

        html.js & .off-canvas__inner{

            @include media-breakpoint-up(lg) {
                height: 815px;
            }
        }

        .off-canvas{

            &__toggle{
                border: 1px solid $color__white!important;

                &--open{
                    top: 10px;
                    right: $grid-gutter-width * .5;

                    @each $breakpoint, $container-padding-size in $container-padding-sizes {
                        @include media-breakpoint-up(#{$breakpoint}) {
                            right: $container-padding-size;
                        }
                    }

                    @media (min-width: #{map-get-last-value($container-max-widths)}) {
                        right: calc(((100vw - #{map-get-last-value($container-max-widths)}) * .5) + #{map-get-last-value($container-padding-sizes)});
                        right: calc((((var(--vw, 1vw) * 100) - #{map-get-last-value($container-max-widths)}) * .5) + #{map-get-last-value($container-padding-sizes)});
                    }

                    span{
                        &.text{
                            display: none;
                        }

                        &.lines{
                            transition: background-color .25s linear;
                        }
                    }

                    @include hover-focus-visible {

                        span.lines,
                        span.lines::before,
                        span.lines::after{
                            background-color: $color__white;
                        }
                    }
                }

                &--close{
                    height: 60px;
                    width: 60px;
                    //z-index: 1;
                    border-color: $color__secondary!important;
                    background-color: $color__secondary;

                    @include media-breakpoint-up(lg) {
                        height: 70px;
                        width: 70px;
                    }

                    @include hover-focus-visible {
                        background-color: $color__white;

                        span::before,
                        span::after{
                            background-color: $color__secondary;
                        }
                    }
                }
            }

            &__inner{

                @include media-breakpoint-up(xxl) {
                    padding-top: calc($header__height--unpinned - 7px);
                }
            }
        }

        .breaking-ribbon{
            position: relative;
        }

        /*
        body[data-template*="home"] &{

             .breaking-ribbon{
                top: 0;
            }
        }*/

        body[data-template*="home"]:not(.navigation-open) &{

            &:before {
                background-color: $header__background-color;
                box-shadow: $header__box-shadow;

                @include media-breakpoint-up(xxl) {
                    height: $header__height--unpinned;
                }

                /*
                html.headroom--top & {
                    height: $header__height--mobile;
                    background-color: transparent;
                    box-shadow: none;

                    @include media-breakpoint-up(lg) {
                        height: $header__height--unpinned;
                    }

                    @include media-breakpoint-up(xxl) {
                        height: $header__height;
                        transition: height .25s ease-in-out;
                    }
                }*/
            }

            /*html.headroom--top &{

                .container-fluid{

                    @include media-breakpoint-up(lg) {
                        height: calc($logo__height - 40px);
                    }

                    @include media-breakpoint-up(xxl) {
                        height: $logo__height;
                    }
                }

                .off-canvas__toggle--open{
                    top: 10px;

                    @include media-breakpoint-up(lg) {
                        top: 26px;
                    }

                    @include media-breakpoint-up(xxl) {
                        top: 40px;
                    }
                }
            }*/

            @include container-child-combinator {
                height: $header__height--mobile;

                @include media-breakpoint-up(lg) {
                    height: $header__height--unpinned;
                }

                /*html.headroom--top & {

                    @include media-breakpoint-up(lg) {
                        height: calc($header__height - 70px);
                    }

                    @include media-breakpoint-up(xxl) {
                        height: $header__height;
                    }
                }*/

                @include media-breakpoint-up(xxl) {
                    height: $header__height--unpinned;
                }
            }
        }

        body[data-template*="home"]:not(.navigation-open).has-breaking-ribbon &{

            /*
            html.headroom--top &  {

                .container-fluid{
                    margin-top: calc(4rem + 5px);

                    @include media-breakpoint-up(xxl) {
                        margin-top: 4rem;
                    }
                }

                .off-canvas__toggle--open {
                    top: calc(4rem + 15px);

                    @include media-breakpoint-up(lg) {
                        top: calc(4rem + 31px);
                    }

                    @include media-breakpoint-up(xxl) {
                        top: calc(4rem + 40px);
                    }
                }
            }*/
        }

        &.page__header > .container-fluid{
            gap: 8px;
            pointer-events: none;

            > *{
                pointer-events: auto;
            }

            @include media-breakpoint-down(lg) {
                height: $header__height--mobile;
            }
        }

        @include container-child-combinator {
            position: fixed;
            z-index: $z-index__header + 1;
            top: 0; right: 0; left: 0;
            display: flex;
            flex-direction: row;
            gap: 0 24px;
            padding-right: $grid-gutter-width * .5;
            height: $header__height--unpinned;

            @include media-breakpoint-up(lg) {
                height: $header__height--unpinned;
            }

            @each $breakpoint, $container-padding-size in $container-padding-sizes {
                @include media-breakpoint-between(md, lg) {
                    padding-right: $container-padding-size + 28px;
                }
            }

            @each $breakpoint, $container-padding-size in $container-padding-sizes {
                @include media-breakpoint-up(#{$breakpoint}) {
                    padding-right: $container-padding-size + 78px;
                }
            }

            @media (min-width: #{map-get-last-value($container-max-widths)}) {
                padding-right: calc(((100vw - #{map-get-last-value($container-max-widths)}) * .5) + #{map-get-last-value($container-padding-sizes)} + 78px);
                padding-right: calc((((var(--vw, 1vw) * 100) - #{map-get-last-value($container-max-widths)}) * .5) + #{map-get-last-value($container-padding-sizes)} + 78px);
            }
        }

        .contact{
            display: flex;
            align-items: center;

            body[data-template*="home"] &{
                margin-top: 0;

                /*
                html.headroom--top & {
                    margin-top: -1px;

                    @include media-breakpoint-up(lg) {
                        margin-top: -9px;
                    }

                    @include media-breakpoint-up(xxl) {
                        margin-top: -50px;
                    }
                }*/
            }

            /*
            html.headroom--top body[data-template*="home"].navigation-open &{
                margin-top: 0;
            }*/

            @include media-breakpoint-down(md) {
                display: none;
            }

            a{
                border: 1px solid transparentize($color: $color__white, $amount: 0.5);
                color: $color__white;
                background-color: $color__secondary;

                width: 70px;
                height: 70px;
                display: block;
                transition: width .25s ease-in-out,height .25s ease-in-out,background-color .25s linear;

                @include media-breakpoint-down(lg) {
                    height: 60px;
                    width: 60px;
                }

                span {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img{
                        filter: $filter__white;
                    }
                }

                &:hover,
                &.focus-within {
                    color: $color__secondary;
                    background-color: $color__white;

                    span img{
                        filter: $filter__secondary;
                    }
                }

                &:focus-within {
                    color: $color__secondary;
                    background-color: $color__white;

                    span img{
                        filter: $filter__secondary;
                    }
                }
            }
        }
    }

    &__main {
        flex-shrink: 0;

        padding-top: $header__height--mobile;

        @include media-breakpoint-up(lg) {
            padding-top: $header__height;
        }

        // position: relative;
        // padding-top: 64px;
        // scroll-margin-top: $header__height--mobile + 64px;

        // @include media-breakpoint-up(lg) {
        //     scroll-margin-top: $header__height--unpinned + 64px;
        // }

        // @include media-breakpoint-up(xxl) {
        //     scroll-margin-top: $header__height + 64px;
        // }

        // &.page__main--padding-top {
        //     padding-top: $header__height--mobile + 64px;

        //     @include media-breakpoint-up(lg) {
        //         padding-top: $header__height--unpinned + 64px;
        //     }

        //     @include media-breakpoint-up(xxl) {
        //         padding-top: $header__height + 64px;
        //     }
        // }

        /*
        body:not([data-template='home']):not(.has-banner-image):not(.has-banner-video):not(.has-breaking-ribbon) & {
            padding-top: $header__height--mobile;

            @include media-breakpoint-up(lg) {
                padding-top: $header__height--unpinned;
            }
        }

        body:not([data-template='home']):not(.has-banner-image):not(.has-banner-video).has-breaking-ribbon & {
            padding-top: $header__height--mobile + 64px;

            @include media-breakpoint-up(lg) {
                padding-top: $header__height--unpinned + 64px;
            }

            @include media-breakpoint-up(xxl) {
                padding-top: $header__height + 64px;
            }
        }*/

        body[data-template*="home"] &{

            > section.section header {
                margin-bottom: 1rem;
            }
        }

        section.section--bg-dark:last-child{
            margin-bottom: 40px;
        }

        body.has-banner-slider &,
        body.has-banner-image &,
        body.has-banner-video &{
            padding-top: 0;
        }
    }

    &__footer {
        color: $color__white;
        margin-top: auto;

        h1,h2,h3,h4,h5,h6, a{
            color: $color__white;
        }

        .row {
            &.row--first {
                margin-bottom: 64px;

                .row {
                    font-size: 22px;
                    line-height: 2;
                    letter-spacing: 0.1em;

                    a {

                        &[href*='mailto:'],
                        &[href*='tel:'] {
                            display: inline;
                            padding-bottom: .125em;
                            text-decoration: none;
                            background: {
                                image: linear-gradient(to right, $color__white 50%, rgba($color__white, .5) 50%);
                                repeat: repeat-x;
                                position: 100% calc(100% - .075em);
                                size: 200% .075em;
                            }
                            transition: background-position .25s ease-in-out;

                            &:hover,
                            &:focus {
                                background-position: 0 calc(100% - .075em);
                            }
                        }
                    }
                }
            }

            &.row--last {
                margin-top: 64px;
                padding-top: 32px;
                background-image: linear-gradient(to right, transparent #{$grid-gutter-width * .5}, rgba($color__white, .5) #{$grid-gutter-width * .5}, rgba($color__white, .5) calc(100% - #{$grid-gutter-width * .5}), transparent calc(100% - #{$grid-gutter-width * .5}));
                background-repeat: no-repeat;
                background-size: 100% 1px;
            }
        }

        .logo {
            float: right;
            margin-top: 10px;
            margin-right: -15px;

            /*
            @media (min-width: #{map-get-last-value($container-max-widths)}) {
                margin-left: calc((100vw - #{map-get-last-value($container-max-widths)}) * .5);
                margin-left: calc(((var(--vw, 1vw) * 100) - #{map-get-last-value($container-max-widths)}) * .5);
            }*/

            img{
                height: 100px;
                width: auto;
            }
        }

        .top-link {
            margin-left: 50px;
            width: 50px;
            height: 50px;

            button {
                position: absolute;
                right: $grid-gutter-width * .5;
                display: block;
                margin-top: calc(-24px - 0.5rem);

                border-radius: 0;
                background-color: $color__link;

                @each $breakpoint, $container-padding-size in $container-padding-sizes {
                    @include media-breakpoint-up(#{$breakpoint}) {
                        right: $container-padding-size;
                    }
                }

                img{
                    transform: rotate(-90deg);
                    filter: $filter__white;
                }

                &:hover,
                &.focus-within {
                    margin-top: calc(-32px - 0.5rem);
                }

                &:focus-within {
                    margin-top: calc(-32px - 0.5rem);
                }
            }

            &.top-link--sticky {
                button {
                    position: fixed;
                    z-index: $z-index__header - 2;
                    right: $grid-gutter-width * .5;
                    bottom: 16px;

                    @each $breakpoint, $container-padding-size in $container-padding-sizes {
                        @include media-breakpoint-up(#{$breakpoint}) {
                            right: $container-padding-size;
                        }
                    }

                    &:hover,
                    &.focus-within {
                        bottom: 24px;
                    }

                    &:focus-within {
                        bottom: 24px;
                    }
                }
            }

            &.top-link--hide {
                button {
                    opacity: 0;
                }
            }
        }
    }
}
