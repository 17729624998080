a {
    font-weight: $font__weight--bold;
    text-decoration: underline;
    text-decoration-thickness: .1em;
    color: $color__link;
    border-radius: 0;
    outline: 0;
    transition: color .25s linear, outline .25s ease-in-out;

    &:hover {
        text-decoration: none;
        //color: $color__link--hover;
    }

    &:focus-visible {
        //color: $color__link--hover;
        // box-shadow: 0 0 0 4px $color__primary--disabled;
        outline: 4px solid $color__primary--disabled;
        // outline-offset: 0;
    }

    &.button {
        padding: 14px 60px 10px 25px;
        position: relative;
        color: $color__white;
        background-color: $color__link;

        border-radius: 0;
        font-weight: $font__weight--bold;

        .text-negative &{

            @include hover-focus-within{
                color: $color__link!important;
            }
        }

        &:after{
            @include arrow;
            transition: all .25s ease-in-out;
        }

        @include hover-focus-within{
            color: $color__link;
            background-color: $color__white;

            &:after{
                filter: $filter__primary;
            }
        }
    }

    &.arrow {
        position: relative;
        display: inline-block;
        padding-right: 30px;
        line-height: $font__line-height;

        &:after{
            @include arrow;
            right: 4px;
            margin-top: -2px;
            filter: $filter__primary;

            .text-negative &{
                filter: $filter__white;
            }
        }
    }
}

@media print {
    a:after {
        display: none;
        content: "";
    }
}
