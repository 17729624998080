nav {
    ul, ol {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            margin: 0;
            padding: 0;

            a {
                display: block;
            }
        }
    }
}

ul {
    &.list-styled,
    .content--editor &,
    .m-p & {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            position: relative;
            margin: 0;
            padding: 0 0 0 20px;
            line-height: $font__line-height;

            &::before {
                content: "";
                position: absolute;
                top: #{$font__line-height * .5}em;
                left: 0;
                @if $button__type == rounded {
                    margin-right: 16px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    transform: translateY(-4px);
                } @else {
                    width: 7px;
                    height: 7px;
                    transform: translateY(-5px);
                }
                background-color: $color__secondary;

                .text-negative &{
                    background-color: $color__white;
                }
            }
        }
    }

    &.list-unstyled {
        margin-bottom: 0;
        @include list-unstyled();
    }
}
