/* overpass-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/overpass-v12-latin/overpass-v12-latin-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/overpass-v12-latin/overpass-v12-latin-300.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* overpass-300italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Overpass';
    font-style: italic;
    font-weight: 300;
    src: url('/assets/fonts/overpass-v12-latin/overpass-v12-latin-300italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/overpass-v12-latin/overpass-v12-latin-300italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* overpass-regular - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/overpass-v12-latin/overpass-v12-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/overpass-v12-latin/overpass-v12-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* overpass-italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Overpass';
    font-style: italic;
    font-weight: 400;
    src: url('/assets/fonts/overpass-v12-latin/overpass-v12-latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/overpass-v12-latin/overpass-v12-latin-italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* overpass-600 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/overpass-v12-latin/overpass-v12-latin-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/overpass-v12-latin/overpass-v12-latin-600.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* overpass-600italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Overpass';
    font-style: italic;
    font-weight: 600;
    src: url('/assets/fonts/overpass-v12-latin/overpass-v12-latin-600italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/overpass-v12-latin/overpass-v12-latin-600italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* overpass-700 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/overpass-v12-latin/overpass-v12-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/overpass-v12-latin/overpass-v12-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* overpass-700italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Overpass';
    font-style: italic;
    font-weight: 700;
    src: url('/assets/fonts/overpass-v12-latin/overpass-v12-latin-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
         url('/assets/fonts/overpass-v12-latin/overpass-v12-latin-700italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
