.text-primary { color: $color__primary; }
.text-secondary { color: $color__secondary !important; }
.text-success { color: $color__success !important; }
.text-danger { color: $color__danger; }
.text-note { color: $color__note; }
.text-gray { color: $color__gray; }
.text-gray-light { color: $color__gray--light; }
.text-negative {
    color: $color__font--negative !important;

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        color: $color__font--negative !important;
    }

    a {
        color: $color__font--negative !important;

        &:hover,
        &:focus {
            color: $color__font--negative !important;
        }
    }
}

.bg-white { background-color: $color__white !important; }
.bg-transparent { background-color: transparent !important; }
.bg-primary { background-color: $color__primary !important; }
.bg-secondary { background-color: $color__secondary !important; }
.bg-success { background-color: $color__success !important; }
.bg-danger { background-color: $color__danger !important; }
.bg-note { background-color: $color__note !important; }
.bg-gray { background-color: $color__gray !important; }
.bg-gray-light { background-color: $color__gray--light !important; }

.border-top { border-top: 1px solid !important; }
.border-right { border-right: 1px solid !important; }
.border-bottom { border-bottom: 1px solid !important; }
.border-left { border-left: 1px solid !important; }
.border-primary { border-color: $color__primary !important; }
.border-secondary { border-color: $color__secondary !important; }
.border-success { border-color: $color__success !important; }
.border-danger { border-color: $color__danger !important; }
.border-note { border-color: $color__note !important; }
.border-gray { border-color: $color__gray !important; }
.border-gray-light { border-color: $color__gray--light !important; }

.filter-primary { filter: $filter__primary; }
.filter-secondary { filter: $filter__secondary !important; }
.filter-danger { filter: $filter__danger; }
.filter-note { filter: $filter__note; }
.filter-gray { filter: $filter__gray; }
.filter-gray-light { filter: $filter__gray--light; }

img {
    &[data-object-fit='cover'] {
        object-fit: cover;
    }

    &[data-object-fit='contain'] {
        object-fit: contain;
    }
}

.button {
    // display: inline-block;
    display: inline-flex;
    flex-direction: row;
    gap: 8px;
    @if $button__style == outlined {
        padding: 11px 32px;
    } @else {
        padding: 12px 32px;
    }
    font-size: 16px;
    font-weight: map-get($font__weights, semi-bold);
    line-height: 1.5;
    text-decoration: none;
    @if $button__style == outlined {
        color: $color__primary;
        // background-color: transparent;
        background-color: $color__white;
        border: 1px solid $color__primary;
    } @else if $button__style == raised {
        color: $color__primary;
        // background-color: transparent;
        background-color: $color__white;
        box-shadow: 0 5px 20px rgba($color__black, .16);
    } @else {
        color: $color__white;
        background-color: $color__primary;
        border: 0;
    }
    @if  $button__style == outlined and $button__type == rounded {
        border-radius: 23px;
    } @else if $button__type == rounded {
        border-radius: 24px;
    } @else {
        border-radius: 0;
    }
    // outline: 0;
    transition: all .25s ease-in-out;

    &:hover {
        color: $color__white;
        background-color: $color__primary--hover;
        @if $button__style == outlined or $button__style == raised {
            border-color: $color__primary--hover;
        }

        img {
            @if $button__style == outlined or $button__style == raised {
                filter: $filter__white;
            }
        }
    }

    &:focus-visible {
        @if $button__style == filled {
            color: $color__white;
            background-color: $color__primary--hover;
        } @else {
            color: $color__primary;
        }
        outline: 4px solid $color__primary--disabled;
        // outline: 4px solid rgba($color__primary, .25);
        outline-offset: 0;
    }

    // @supports selector(:has(*)) {
    //     &:has(span.icon):has(span:not(.icon):not(.visually-hidden)) {
    //         display: inline-flex;
    //         flex-direction: row;
    //         gap: 8px;

    //         span {
    //             &.icon {
    //                 flex-shrink: 0;
    //                 // margin-right: 8px;
    //             }
    //         }
    //     }
    // }

    &--icon {
        @if $button__style == outlined {
            padding-right: 11px;
            padding-left: 11px;
        } @else {
            padding-right: 12px;
            padding-left: 12px;
        }
    }

    img {
        width: auto;
        height: 24px;
        transition: filter .25s ease-in-out;

        @if $button__style == outlined {
            filter: $filter__primary;
        } @else if $button__style == raised {
            filter: $filter__primary;
        } @else {
            filter: $filter__white;
        }
    }

    span {
        &.icon {
            flex-shrink: 0;
        }
    }
}

.css-icon,
.icon {
	&[class*='icon--'],
	&[class*='css-icon--']{
		display: inline-block;
		position: relative;
		margin-right: 8px;
		font-size: 24px;
		font-weight: normal;
		font-style: normal;
		line-height: 1;
		width: 24px;
		height: 24px;
	}

	&--calendar {
		&::before {
			content: "";
			position: absolute;
			top: 4px;
			left: 8px;
			width: 2px;
			height: 4px;
			background-color: currentColor;
			border-radius: 1px;
			box-shadow: 6px 0;
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 4px;
			left: 4px;
			width: 16px;
			height: 14px;
			border-top: 4px solid currentColor;
			border-bottom: 8px solid currentColor;
			border-radius: 2px;
		}
	}

	&--clock {
		&::before {
			content: "";
			position: absolute;
			top: 50%; left: 50%;
			width: 16px;
			height: 16px;
			background-color: currentColor;
			border-radius: 50%;
			transform: translate(-50%, -50%);
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 50%;
			left: 50%;
			width: 5px;
			height: 6px;
			border-bottom: 2px solid $color__white;
			border-left: 2px solid $color__white;
			transform: translate(-1px, 1px) skewY(30deg);
			transform-origin: 1px calc(100% - 1px);
		}
	}

	//&--marker {
	//	&::before {
	//		content: "";
	//		position: absolute;
	//		top: 2px;
	//		left: 5px;
	//		width: 14px;
	//		height: 14px;
	//		background-color: currentColor;
	//		border-radius: 50% 50% 0 50%;
	//		transform: rotate(45deg);
	//	}
	//
	//	&::after {
	//		content: "";
	//		position: absolute;
	//		bottom: 2px;
	//		left: 5px;
	//		width: 14px;
	//		height: 2px;
	//		background-color: currentColor;
	//		border-radius: 1px;
	//	}
	//}

	&--marker {
		&::before {
			content: "";
			position: absolute;
			top: 3px;
			left: 50%;
			width: 16px;
			height: 16px;
			background-color: currentColor;
			border-radius: 50% 50% 0 50%;
			transform: translateX(-50%) rotate(45deg);
		}

		&::after {
			content: "";
			position: absolute;
			top: .3125em; // 7.5px
			left: 50%;
			width: 7px;
			height: 7px;
			background-color: $color__white;
			border-radius: 50%;
			transform: translateX(-50%);
		}
	}

	//&--marker {
	//	&::before {
	//		content: "";
	//		position: absolute;
	//		top: 1px;
	//		left: 50%;
	//		width: 16px;
	//		height: 16px;
	//		background-color: transparent;
	//		//border: 5px solid currentColor;
	//		border: .1875em solid currentColor; // 4.5px
	//		border-radius: 50%;
	//		transform: translateX(-50%);
	//	}
	//
	//	&::after {
	//		content: "";
	//		position: absolute;
	//		//top: 12px;
	//		top: 13px;
	//		left: 50%;
	//		width: 0;
	//		height: 0;
	//		background-color: transparent;
	//		border-top: .4em solid currentColor;
	//		border-right: .29em solid transparent;
	//		border-left: .29em solid transparent;
	//		transform: translateX(-50%);
	//	}
	//}

	&--bed {
		background-image: linear-gradient(transparent 15%, currentcolor 15%, currentcolor 90%, transparent 90%), linear-gradient(transparent 60%, currentcolor 60%, currentcolor 75%, transparent 75%), linear-gradient(transparent 60%, currentcolor 60%, currentcolor 90%, transparent 90%);
		background-position: left center, left top, right bottom;
		background-repeat: no-repeat, no-repeat, no-repeat;
		background-size: 10% 100%, auto, 10% 100%;

		&::before {
			content: "";
			position: absolute;
			bottom: 45%;
			right: 55%;
			width: 27.5%;
			height: 27.5%;
			background-color: currentcolor;
			border-radius: 50%;
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 40%;
			left: 50%;
			width: 50%;
			height: 32.5%;
			background-color: currentcolor;
			border-radius: 0 .2em 0 0;
		}
	}
}

.ui-widget {
    font-size: 1.4rem !important;
}
