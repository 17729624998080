header .banner{

    &.banner--slider:not(.banner--fullscreen) figure picture{
        height: auto;
    }

    &.banner.banner--video:not(.banner--fullscreen){

        figure{
            padding-top: 35%;
            min-height: 250px;

            @include media-breakpoint-up(md) {
                min-height: 300px;
            }
        }

        .button{
            position: relative;
            z-index: 1;

            color: $color__white;
            background-color: $color__primary;

            img{
                filter: $filter__white;
            }

            @include hover-focus-within{
                color: $color__primary;
                background-color: $color__white;

                img{
                    filter: $filter__primary;
                }
            }
        }
    }

    &.banner--slider,
    &.banner--video {
        //padding-top: 0;

        .splide__footer,
        .banner__footer{
            bottom: 15px;

            @include media-breakpoint-up(md) {
                bottom: auto;
                margin-top: -32px;
                background-color: $color__white;
            }

            @include media-breakpoint-up(lg) {
                margin-top: -40px;
            }
        }
    }
}
