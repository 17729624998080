.logo {
    &:not(.logo--footer) {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: auto;
        @if $main-navigation__mode == off-canvas {
            margin-left: $grid-gutter-width * .5;

            @each $breakpoint, $container-padding-size in $container-padding-sizes {
                @include media-breakpoint-up(#{$breakpoint}) {
                    margin-left: $container-padding-size;
                }
            }

            @media (min-width: #{map-get-last-value($container-max-widths)}) {
                margin-left: calc(((100vw - #{map-get-last-value($container-max-widths)}) * .5) + #{map-get-last-value($container-padding-sizes)});
                margin-left: calc((((var(--vw, 1vw) * 100) - #{map-get-last-value($container-max-widths)}) * .5) + #{map-get-last-value($container-padding-sizes)});
            }
        }

        body[data-template*="home"] &{

            span{
                display: flex;
            }
        }
    }

    &__inner {
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        // height: $header__height--mobile;

        // @include media-breakpoint-up(lg) {
        //     height: $header__height--unpinned;
        // }

        // @include media-breakpoint-up(xxl) {
        //     height: $header__height;
        //     transition: height .25s ease-in-out;
        // }

        // html.headroom--unpinned & {
        //     @include media-breakpoint-up(xxl) {
        //         height: $header__height--unpinned;
        //     }
        // }

        a {
            display: block;

            &[tabindex='-1'] {
                pointer-events: none;
            }

            span {
                display: block;
                height: $logo__height--mobile;
                // line-height: 0;
                // font-size: 0;
                // color: transparent;

                @media (max-width: 450px) {
                    height: 16vw;
                }

                @include media-breakpoint-up(lg) {
                    height: $logo__height--unpinned;
                }

                /*
                body[data-template*="home"]:not(.navigation-open) &{
                    margin-top: 0;

                    @include media-breakpoint-up(xxl) {
                        height: $logo__height--unpinned;
                        transition: height .25s ease-in-out;
                    }


                    html.headroom--top & {

                        @include media-breakpoint-up(lg) {
                            height: calc($logo__height - 70px);
                        }

                        @include media-breakpoint-up(xxl) {
                            height: $logo__height;
                        }
                    }
                }*/

                img {
                    height: 100%;

                    &:nth-child(2){
                        margin-left: 5px;

                        @include media-breakpoint-up(sm) {
                            margin-left: 15px;
                        }
                    }
                }
            }
        }
    }
}
