// see public/bundles/hitcomfrontend/scss/templates/abstracts/_mixins.scss for core mixins

@mixin arrow() {
    content:'';
    position: absolute;
    width: 1rem;
    height: 1rem;

    right: 20px;
    top: 50%;
    transform: translate(0, -50%);

    background: url('/bundles/hitcomfrontend/img/material-design-icons/outlined/arrow_right_alt.svg') no-repeat center;
    filter: $filter__white;
}
